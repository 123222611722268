import {
  React, Component, View, ScrollView, styleSpread, Button, logAnalyticsEvent, resourceActions,
  Text, TouchableOpacity, Image, Loading, TextInput
} from '~/components'; //eslint-disable-line
import { Animated, Pressable } from 'react-native';
import react, { useState, useEffect } from 'react';

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Popup, Label, confirm, withKeyEvents, AttentionIndicator } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { sortedTodosFor } from '~helpers/todos';

import lib, { api, colors } from '@symbolic/lib';

import _ from '@symbolic/lodash';
import K from '~/k';
import styles from './todo.styles';
import trashIcon from '~/assets/trash-icon.png';
import { render } from 'react-dom';

var s = styleSpread(styles);

class Todo extends Component {
  state = {};

  getInputRef = (ref) => this.inputRef = ref;
  getTextInputRef = (ref) => this.textInputRef = ref;

  // componentDidMount() {
  //   if (this.inputRef && !_.trim(this.props.todo.body)) {
  //     setTimeout(() => this.inputRef.focus());
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.inputRef && this.props.activeTodoId === this.props.todo.id && prevProps.activeTodoId !== this.props.todo.id) {
      this.inputRef.focus();
    }
  }

  handleBodyChange = ({value}) => {
    this.props.updateTodo({id: this.props.todo.id, props: {body: value}});
  }

  handleRankUpdate = ({newRank, newBody}) => {
    var updates = [];
    var sortedTodos = sortedTodosFor({todos: this.props.todos});

    var oldRank = _.map(sortedTodos, 'id').indexOf(this.props.todo.id);
    var oldIds = _.map(sortedTodos, 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;

    if (oldRank !== newRank) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => {
        updates.push({where: {id}, props: {rank, ...((id === this.props.todo.id && newBody) ? {body: newBody} : {})}})
      });

      this.props.updateTodos({updates});
    }
  }

  handleTodoBlur = ({value}) => {
    setTimeout(() => {
      if (this.props.activeTodoId === this.props.todo.id) {
        this.props.setActiveView({data: {activeTodoId: null}});
      }
    });

    if (value === '' && this.props.index !== 0) {
      this.props.destroyTodo({id: this.props.todo.id});
    }
    else {
      if (_.includes(value, '!')){
        var match = /!(?<command>[a-z]*)/gm.exec(value);

        if (match) {
          var {command} = match.groups;

          if (command === 'priority') {
            var match = /!(?<command>[a-z]*) (?<argument>\d*)/gm.exec(value);

            var {argument} = match.groups;

            if (_.isNumber(_.toNumber(argument))) {
              value = _.trim(value.replace(`!priority ${argument}`, ''));

              this.handleRankUpdate({newRank: _.toNumber(argument) - 1, newBody: value});
            }
          }
          else if (command === 'top') {
            value = _.trim(value.replace(`!top`, ''));

            this.handleRankUpdate({newRank: 0, newBody: value});
          }
          else if (command === 'last') {
            value = _.trim(value.replace(`!last`, ''));

            this.handleRankUpdate({newRank: _.map(this.props.todos).length + 1, newBody: value});
          }
          else if (command === 'snooze') {
            //setup snoozing!
          }
          else if (command === 'color') {
            //set dot color based on input
          }
          else if (command === 'delete') {
            this.props.destroyTodo({id: this.props.todo.id});
          }
        }
      }
    }
  }

  handleDotPress = () => {
    var {todo} = this.props;

    this.props.updateTodo({id: todo.id, props: {status: todo.status === 'complete' ? 'incomplete' : 'complete'}});
  }

  handleKeyPress = async (event) => {
    if (event.code === 'Enter') {
      if (!event.altKey) {
        event.preventDefault();

        this.props.createTodoAndUpdateRanks({rank: this.props.todo.rank + 1});

        this.textInputRef.handleBlur(); //HINT trigger change event
      }
      else {
        var target = this.inputRef;
        let cursorPosition = target.selectionStart;
        let textBeforeCursorPosition = target.value.substring(0, cursorPosition);
        let textAfterCursorPosition = target.value.substring(cursorPosition, target.value.length);

        target.value = this.todoBody = textBeforeCursorPosition + '\n' + textAfterCursorPosition;

        this.textInputRef.handleChangeText(this.todoBody); //HINT trigger input event, update scrollheight on web
      }
    }
    else if (event.code === 'Backspace') {
      if (this.todoBody !== undefined ? !this.todoBody : !this.props.todo.body) {
        this.props.destroyTodo({id: this.props.todo.id});

        //TODO focus on previous
      }
    }
  }

  get isFocused() {
    return this.props.todo.id === this.props.activeTodoId;
  }

  handleTextPress = () => {
    if (!this.isFocused) {
      this.props.setActiveView({data: {activeTodoId: this.props.todo.id}});

      if (K.isWeb) {
        //HINT move the cursor to the click position (grabbed from story-row.js in stories)
        //HINT not working - at least on single click
        var selection = window.getSelection();

        if (selection.rangeCount > 0) {
          var {startOffset: start, endOffset: end} = selection.getRangeAt(0);

          this.setState({editingTitleSelection: {start, end}});
        }
      }
    }
  }

  delete = () => {
    this.props.destroyTodo({id: this.props.todo.id});
  }

  render() {
    var {todo, viewMode} = this.props;

    var diameter = K.step.height;

    var isEditing = this.props.activeTodoId === this.props.todo.id;

    var dotStyle = {
      width: diameter,
      height: diameter,
      borderRadius: diameter / 2,
      borderWidth: 1,
      backgroundColor: todo.status === 'complete' ? todo.color : `${todo.color}33`,
      borderColor: todo.color,
    }

    return (
      <View
        dataSet={{todo: 1}}
        style={{marginBottom: K.margin, flexDirection: 'row', ...(todo.status === 'complete' ? {opacity: 0.5} : {})}}
      >
        <TouchableOpacity
          onPress={this.handleDotPress}
          style={{marginRight: K.margin, justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', ...dotStyle}}
        />
        <Pressable
          onLongPress={this.props.drag}
          style={{flex: 1, flexDirection: 'row', borderRadius: K.step.height / 2, backgroundColor: `${todo.color}33`, borderWidth: 1, borderColor: isEditing ? 'black' : 'transparent'}}
        >
          {/* <TextInput
            multiline
            inputStyle={{...(viewMode === 'zen' ? {backgroundColor: 'white'} : {})}}
            style={{opacity: 0.3, height: '100%', width: 30 + 4, paddingLeft: 4, textAlign: 'center', paddingHorizontal: 0, minHeight: K.step.height, paddingVertical: 7, borderRadius: K.step.height / 2, ...(todo.status === 'complete' ? {textDecorationLine: 'line-through', textDecorationStyle: 'solid'} : {})}}
            value={this.props.index + 1}
            // onInput={this.handleTodoInput}
          /> */}
          {isEditing ? (
            <TextInput
              multiline
              initialSelection={[todo.body.length, todo.body.length]}
              onBlur={this.handleTodoBlur}
              ref={this.getTextInputRef}
              getInputRef={this.getInputRef}
              inputStyle={{...(viewMode === 'zen' ? {backgroundColor: 'white'} : {})}}
              style={{flex: 1, minHeight: K.step.height - 5, paddingVertical: 6, paddingTop: 7, backgroundColor: 'transparent', paddingHorizontal: K.step.height / 2, ...(todo.status === 'complete' ? {textDecorationLine: 'line-through', textDecorationStyle: 'solid'} : {})}}
              value={todo.body}
              onInput={({value}) => this.todoBody = value}
              onChange={this.handleBodyChange}
              onKeyPress={this.handleKeyPress}
            />
          ) : (
            <Text
              style={{flex: 1, paddingVertical: 7, paddingHorizontal: K.step.height / 2}}
              onPress={this.handleTextPress}
            >{todo.body}</Text>
          )}
          {/* {isEditing && (
          )} */}
          {K.isWeb && (
            <TouchableOpacity
              onPress={this.delete}
              dataSet={{deleteIcon: 1}}
              style={{width: K.step.height, height: K.step.height, alignItems: 'center', justifyContent: 'center', opacity: 0.5}}
            >
              <Image source={trashIcon} style={{...K.defaultIconSize}}/>
            </TouchableOpacity>
          )}
        </Pressable>
      </View>
    )
  }
}

export default withSafeAreaInsets(connect({
  mapState: (state, ownProps) => ({
    todos: state.resources.todos.byId,
    todo: _.find(state.resources.todos.byId, {id: ownProps.todoId}),
    notepads: state.resources.notepads.byId,
    ..._.pick(state.activeView.data, ['tutorialStep', 'showingTutorial', 'activeTodoId'])
  }),
  mapDispatch: {
    ..._.pick(resourceActions.todos, ['trackTodos', 'updateTodo', 'updateTodos', 'destroyTodo', 'createTodo']),
    ..._.pick(resourceActions.notepads, ['trackNotepads', 'updateNotepad', 'destroyNotepad']),
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers, setAppData,
  }
})(Todo));
