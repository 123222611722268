import {
    React, Component, View, ScrollView, styleSpread, Button, logAnalyticsEvent, resourceActions,
    Text, TouchableOpacity, Image, Loading, TextInput
  } from '~/components'; //eslint-disable-line
import { Animated, Pressable } from 'react-native';

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Popup, Label, confirm, LabelledView } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { AttentionIndicator } from '@symbolic/rn-lib';
import { sortedTodosFor } from '~helpers/todos';

import DraggableFlatList from 'react-native-draggable-flatlist';

import { api, colors } from '@symbolic/lib';

import _ from '@symbolic/lodash';
import K from '~/k';
import styles from './standard-view.styles';

import Todo from '~/components/todo/todo';
import TodoForm from '~/components/todo-form/todo-form';

class StandardView extends Component {
  state = {
    showCompleted: false
  };

  constructor(props) {
    super(props);

    this.lineYs = {};

    this.debouncedForceRender = _.debounce(() => this.forceUpdate(), 10);
    this.debouncedUpdateNotepad = _.debounce(({...args}) => this.props.updateNotepad({...args}), 100);
  }

  async componentDidMount() {
    var showCompleted = await sessionStore.get('@showCompleted');

    this.setState({showCompleted});
  }

  handleDragEnd = ({data}) => {
    var updates = _.map(_.filter(data, {type: 'todo'}), ({id}, rank) => ({where: {id}, props: {rank}}));

    this.props.updateTodos({updates});
  }

  renderItem = ({item, index, drag}) => {
    if (item.type === 'todo') {
      var {todo} = item;
      var {createTodoAndUpdateRanks} = this;

      return (
        <Todo key={todo.id} todoId={todo.id} {...{index, drag, createTodoAndUpdateRanks}}/>
      );
    }
    else if (item.type === 'todo-form') {
      return <TodoForm index={0}/>;
    }
    else if (item.type === 'show-completed-toggle') {
      return (
        <Pressable
          style={{paddingLeft: K.spacing + K.margin + K.step.height, paddingVertical: K.margin, marginBottom: K.margin}}
          onPress={() => {
            this.setState({showCompleted: !this.state.showCompleted});

            sessionStore.set('@showCompleted', !this.state.showCompleted);
          }}
        >
          <Label>{this.state.showCompleted ? 'Hide' : 'Show'} Completed</Label>
        </Pressable>
      )
    }
  }

  onNewTodo = (todo) => {
    this.props.onNewTodo(todo);
  }

  updateTodo = ({id, key, value}) => {
    this.props.updateTodo({id, key, value});
  }

  createTodoAndUpdateRanks = async ({rank}) => {
    var {session} = this.props;

    var todosToUpdateRank = _.filter(this.props.todos, todo => todo.rank >= rank);

    await api.update('todos', _.map(todosToUpdateRank, todo => ({where: {id: todo.id}, props: {rank: todo.rank + 1}})));

    var todo = await api.create('todo', {body: '', status: 'incomplete', color: '#cccccc', userId: session.user.id, orgId: session.activeOrg.id, rank});

    this.props.trackTodos({todos: [todo, ..._.map(todosToUpdateRank, todo => ({...todo, rank: todo.rank + 1}))]});
    this.props.setActiveView({data: {activeTodoId: todo.id}});
  }

  //TODO pending rn-lib publish - text-input added props.onKeyDown
  handleNotesKeydown = (event) => {
    if (event.key == 'Tab') {
      event.preventDefault();

      var target = this.notesInputRef;
      var start = target.selectionStart;
      var end = target.selectionEnd;

      target.value = target.value.substring(0, start) + "\t" + target.value.substring(end);
      target.selectionStart = target.selectionEnd = start + 1;

      this.notesTextInputRef.handleChangeText(target.value);
    }
  }

  render() {
    var {todos, notepad} = this.props;

    var diameter = K.step.height;

    var dotStyle = {
      width: diameter,
      height: diameter,
      borderRadius: diameter / 2,
      borderWidth: 1
    };

    var lines = _.split(this.state.notepadBody || notepad.body || '', '\n');

    var flatListData = [
      {type: 'todo-form'}
    ];

    var sortedTodos = sortedTodosFor({todos});

    _.forEach(_.filter(sortedTodos, {status: 'incomplete'}), (todo, index) => flatListData.push({type: 'todo', id: todo.id, todo}));

    flatListData.push({type: 'show-completed-toggle', id: 'show-completed-toggle'});

    if (this.state.showCompleted) {
      _.forEach(_.filter(sortedTodos, {status: 'complete'}), (todo, index) => flatListData.push({type: 'todo', id: todo.id, todo}));
    }

    return (
      <View style={{flex: 1, ...(K.isWeb ? {flexDirection: 'row'} : {})}}>
        <View style={{flex: 1, ...(K.isWeb ? {flexDirection: 'row'} : {alignItems: 'stretch', alignSelf: 'stretch'})}}>
          <ScrollView
            contentContainerStyle={{padding: K.spacing}}
            style={{flex: 1, ...(K.isWeb ? {width: '50%', height: '100%'} : {height: '50%'})}}
          >
            <DraggableFlatList
              data={flatListData}
              renderItem={this.renderItem}
              keyExtractor={item => `draggable-item-${item.id}`}
              keyboardShouldPersistTaps='handled'
              onDragEnd={this.handleDragEnd}
              dataSet={{'draggable-flatlist': 1}}
            />
          </ScrollView>
          <View style={{...(K.isWeb ? {borderLeftWidth: 1} : {borderTopWidth: 1}), borderColor: 'rgba(0, 0, 0, 0.1)'}}></View>
          <View style={{flex: 1, position: 'relative', padding: K.spacing, ...(K.isWeb ? {width: '50%', height: '100%'} : {height: '50%'})}}>
            <TextInput
              placeholder={'NOTES HERE...'}
              multiline
              ref={ref => this.notesTextInputRef = ref}
              getInputRef={ref => this.notesInputRef = ref}
              onKeyDown={this.handleNotesKeydown}
              value={notepad.body || ''}
              style={{lineHeight: K.calcFont(20), flex: 1, padding: K.spacing / 2, paddingLeft: K.spacing * 3, height: '100%', borderRadius: K.step.height / 2}}
              onChange={({value}) => this.props.updateNotepad({id: notepad.id, props: {body: value}})}
              onInput={({value}) => {
                this.setState({notepadBody: value});
                this.debouncedUpdateNotepad({id: notepad.id, props: {body: value}});
              }}
            />
            <View style={{position: 'absolute', top: 0, left: 0}}>
              <View style={{position: 'relative'}}>
                {_.map(lines, (line, l) => !!line && (
                  <TouchableOpacity
                    key={l+line}
                    style={{position: 'absolute', left: K.spacing * 2, top: (this.lineYs[l] || 0) + K.spacing * 1.5 + 4, paddingHorizontal: 0}}
                    onPress={() => {
                      //HINT remove whitespace lines before and after, except for one
                      var lineIndicesToRemove = [l];
                      var keptOneEmptyLine = false;

                      //HINT keep up to one empty line if it has text above and below
                      var keepSomeWhitespace = _.some(_.take(lines, l - 1), line => _.trim(line).length > 0) && _.some(_.takeRight(lines, lines.length - l - 1), line => _.trim(line).length > 0);

                      var _l = l;
                      while (_l > 0 && _.trim(lines[_l - 1]).length === 0) {
                        if (!keptOneEmptyLine && keepSomeWhitespace) keptOneEmptyLine = true;
                        else lineIndicesToRemove.push(_l - 1);

                        _l -= 1;
                      }

                      _l = l;
                      while (_l < lines.length - 1 && _.trim(lines[_l + 1]).length === 0) {
                        if (!keptOneEmptyLine && keepSomeWhitespace) keptOneEmptyLine = true;
                        else lineIndicesToRemove.push(_l + 1);

                        _l += 1;
                      }

                      var _lines = _.reject(lines, (_line, _l) => _.includes(lineIndicesToRemove, _l));
                      var body = _.join(_lines, '\n');

                      this.setState({notepadBody: body});
                      this.props.updateNotepad({id: notepad.id, props: {body}});

                      var {session} = this.props;
                      var updates = _.map(this.props.todos, todo => ({props: {rank: todo.rank + 1}, where: {id: todo.id}}));
                      var body = _.trim(_.trimStart(_.replace(_.trim(line), /^\d+\s*[-\\.)]\s+/g, ''), '-'));

                      this.props.updateTodos({updates});
                      this.props.createTodo({props: {body, status: 'incomplete', color: '#cccccc', userId: session.user.id, orgId: session.activeOrg.id, rank: 0}});
                    }}
                  >
                    <View style={{width: 12, height: 12, borderRadius: 10, borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.3)'}}></View>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          </View>
          <View style={{position: 'absolute', top: '300%', width: '50%', paddingLeft: K.spacing * 4, paddingRight: K.spacing/*, top: '50%', left: '50%', marginLeft: -K.spacing * 1, , backgroundColor: 'red'*/}}>
            {_.map(lines, (line, l) => (
              <Text
                key={lines.join('')+l}
                style={{lineHeight: K.calcFont(20)}}
                onLayout={event => {
                  this.lineYs[l] = event.nativeEvent.layout.y;

                  this.debouncedForceRender();
                }}
              >{line || ' '}</Text>
            ))}
          </View>
        </View>
      </View>
    )
  }
}

export default withSafeAreaInsets(connect({
  mapState: state => ({
    todos: state.resources.todos.byId,
    notepad: _.map(state.resources.notepads.byId)[0],
    ..._.pick(state.activeView.data, ['tutorialStep', 'showingTutorial'])
  }),
  mapDispatch: {
    ..._.pick(resourceActions.todos, ['trackTodos', 'updateTodo', 'updateTodos', 'destroyTodo', 'createTodo']),
    ..._.pick(resourceActions.notepads, ['trackNotepads', 'updateNotepad', 'destroyNotepad']),
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers, setAppData,
  }
})(StandardView));
