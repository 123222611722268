import _ from 'lodash';
import { createStore, resourcesReducerAndActionsFor, connect, resourceClassesFor } from '@symbolic/redux';
import lib from '@symbolic/lib';
import {activeViewReducer, eventReducer} from '../redux/index';
import React, { Component } from 'react';
import { StyleSheet, KeyboardAvoidingView, View, Image, TouchableOpacity as _TouchableOpacity, Pressable as _Pressable, Platform } from 'react-native';
import { styleSpread, prepareToAnimate, Text, Button, TextInput, ScrollView, LabelledView, PickerInput, Popup, commentsClassFor, notificationsClassFor, Loading, Link, DateInput, Tooltip, AccessManager as _AccessManager } from '@symbolic/rn-lib';

import * as Analytics from 'expo-firebase-analytics';
import { DEFAULT_APP_OPTIONS } from 'expo-firebase-core';

var {actions: resourceActions, reducer: resourcesReducer} = resourcesReducerAndActionsFor({
  comments: {},
  notifications: {},
  users: {},
  todos: {},
  notepads: {}
});

var {trackComments, destroyComments} = resourceActions.comments;
var {trackNotifications, updateNotifications, destroyNotifications} = resourceActions.notifications;
var {trackTodos, updateTodos, destroyTodos} = resourceActions.todos;

var store = createStore({
  reducers: {resources: resourcesReducer, activeView: activeViewReducer, event: eventReducer},
  initialState: {activeView: {data: {}}, event: {}}
});

var {
  CommentComponent,  CommentsComponent,
  NotificationComponent, NotificationsComponent,
} = resourceClassesFor(
  ['comments', 'notifications'],
  {store, resourceActions, Component}
);

if (Platform.OS === 'web') {
  var firebase = require('firebase/app').default;

  if (DEFAULT_APP_OPTIONS && !firebase.apps.length) {
    firebase.initializeApp(DEFAULT_APP_OPTIONS);
  }
}

var logAnalyticsEvent = (name) => {
  if (process.env.NODE_ENV === 'production' && !global.IS_HENRYBUILT_USER) {
    if (Platform.OS === 'web') {
      if (DEFAULT_APP_OPTIONS && !firebase.apps.length) {
        firebase.initializeApp(DEFAULT_APP_OPTIONS);
      }

      require('firebase/analytics');

      firebase.analytics().logEvent(name);
    }
    else {
      Analytics.logEvent(name);
    }
  }
}

var Comments = commentsClassFor({CommentsComponent, CommentComponent, connect, resourceActions, appKey: 'weflowLite'});
var Notifications = notificationsClassFor({NotificationsComponent, NotificationComponent, resourceActions, appKey: 'weflowLite'});

var AccessManager = props => (<_AccessManager behaviorMap={{inactive: 'showPopup'}} {...props}/>);
var TouchableOpacity = props => (<_TouchableOpacity {...props} onPress={props.overrideOnPress ? props.overrideOnPress : props.onPress}/>);
var Pressable = props => (<_Pressable {...props} onPress={props.overrideOnPress ? props.overrideOnPress : props.onPress}/>);

export {
  React, Component,
  StyleSheet, View, Image, TextInput, ScrollView, KeyboardAvoidingView, Link, Loading, DateInput,
  Button, Text, LabelledView, TouchableOpacity, Pressable, PickerInput, Popup, Comments, Notifications, Tooltip,
  AccessManager,
  _, lib,
  styleSpread, prepareToAnimate, store, logAnalyticsEvent,
  resourceActions, resourcesReducer,
  trackComments, destroyComments, trackNotifications, updateNotifications, destroyNotifications, trackTodos, updateTodos, destroyTodos
};
