import { K as LibK } from '@symbolic/rn-lib/dist/styles/index';

var K = {
  ...LibK,
  fonts: {
    ...LibK.fonts,
    sl: {fontSize: LibK.calcFont(14), letterSpacing: LibK.calcFont(1)},
    su: {fontSize: LibK.calcFont(12), letterSpacing: LibK.calcFont(1.5), textTransform: 'uppercase'},
    pageHeader: {...(LibK.isWeb ? {fontSize: 18, letterSpacing: 1} : {fontSize: 18, letterSpacing: 1}), fontWeight: 'bold'}
  },
  explanationText: {
    marginTop: LibK.spacing,
    padding: LibK.spacing,
    fontStyle: 'italic'
  },
  step: {
    height: 30,
    small: {
      diameter: LibK.isWeb ? 27 : 34,
      margin: 3
    }
  },
  rowHeight: LibK.isWeb ? 37 : 60,
  instanceRowHeight: LibK.isWeb ? 36 : 50,
  stickyColumnWidth: LibK.isWeb ? 220 : 150,
  // borderRadius: LibK.isWeb ? 32 / 2 : 44 / 2,
  // spacing: LibK.calc(15),
};

export default K;
