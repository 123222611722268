import {
    React, Component, View, ScrollView, styleSpread, Button, logAnalyticsEvent, resourceActions,
    Text, TouchableOpacity, Image, Loading, TextInput
  } from '~/components'; //eslint-disable-line
	import { Animated } from 'react-native';
	import { Fragment } from 'react';
  
  import { setActiveView, setEvent } from '~/redux/index.js';
  import { connect, setAppData } from '@symbolic/redux';
  import { Popup, Label, confirm, LabelledView } from '@symbolic/rn-lib';
  import { withSafeAreaInsets } from 'react-native-safe-area-context';
  import { AttentionIndicator } from '@symbolic/rn-lib';
	import { sortedTodosFor } from '~helpers/todos';
  
  import { api, colors } from '@symbolic/lib';
  
  import _ from '@symbolic/lodash';
  import K from '~/k';
  import styles from './zen-view.styles';

  import Todo from '~/components/todo/todo';
  import TodoForm from '~/components/todo-form/todo-form';

    
  var s = styleSpread(styles);
  
  class ZenView extends Component {

		onNewTodo = (todo) => {
			this.props.onNewTodo(todo);
		}

    updateTodo = ({id, key, value}) => {
			this.props.updateTodo({id, key, value});
    }
  
    render() {
			var {todos, notepad} = this.props;
			
			var diameter = K.step.height;

			var dotStyle = {
				width: diameter,
				height: diameter,
				borderRadius: diameter / 2,
				borderWidth: 1
			}

			// return (
			// 	<Fragment>
			// 		<View style={{flex: 1, alignItems: 'center'}}>
			// 			<View style={{padding: K.margin * 2, flex: 1, width: 600}}>
			// 				<LabelledView label={'Priorities'} styles={{outerView: {marginBottom: K.spacing}, label: {marginBottom: K.spacing}}}>
			// 					{_.map(_.filter(todos, (todo, i) => i < 3), (todo, i) => <Todo key={todo.id} todoId={todo.id}/>)}
			// 					{_.map(todos).length < 3 && <TodoForm index={_.map(todos).length} autofocus={false} color={colors.colorFor(_.map(todos).length)}/>}
			// 				</LabelledView>
			// 				<LabelledView label={'Notepad'} styles={{innerView: {flex: 1, height: '100%'}, outerView: {flex: 1, width: '100%', marginBottom: K.margin, marginRight: K.margin}, label: {marginBottom: K.spacing}}}>
			// 						<TextInput multiline value={notepad.body} style={{minHeight: 150, padding: 5, height: '100%'}} onChange={({value}) => this.props.updateNote({id: notepad.id, props: {body: value}})}/>
			// 				</LabelledView>
			// 			</View>
			// 		</View>
			// 		<Button text={'Standard'} onPress={() => this.props.updateViewMode('standard')} style={{position: 'absolute', top: 0, left: 0, marginTop: K.margin, marginLeft: K.margin}}/>
			// 	</Fragment>
      // )

			return (
				<Fragment>
					<View style={{flex: 1, alignItems: 'center'}}>
						<View style={{padding: K.margin * 2, flex: 1, width: 700}}>
							<LabelledView styles={{outerView: {marginBottom: K.spacing, top: '15%'}, label: {marginBottom: K.spacing}}}>
								{_.map(_.filter(sortedTodosFor({todos}), (todo, i) => i < 3), (todo, i) => <Todo key={todo.id} todoId={todo.id} viewMode={'zen'}/>)}
								{_.times(3 - _.map(todos).length, (i) => <TodoForm key={i} viewMode={'zen'} index={_.map(todos).length} autofocus={false} color={colors.colorFor(_.map(todos).length + i)}/>)}
							</LabelledView>
						</View>
					</View>
					<Button text={'Standard'} onPress={() => this.props.updateViewMode('standard')} style={{position: 'absolute', top: 0, left: 0, marginTop: 2 * K.margin, marginLeft: 2 * K.margin}}/>
				</Fragment>
      )
    }
  }
  
  export default withSafeAreaInsets(connect({
    mapState: state => ({
			todos: state.resources.todos.byId,
			notepad: _.map(state.resources.notepads.byId)[0],
      ..._.pick(state.activeView.data, ['tutorialStep', 'showingTutorial'])
    }),
    mapDispatch: {
			..._.pick(resourceActions.todos, ['trackTodos', 'updateTodos', 'destroyTodos']),
			..._.pick(resourceActions.notepads, ['trackNotepads', 'updateNotepad', 'destroyNotepad']),
      setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers, setAppData,
    }
  })(ZenView));
  