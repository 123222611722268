import _ from 'lodash';

function sortedTodosFor({todos}) {
  return _.sortBy(todos, [
    todo => todo.status === 'incomplete' ? 0 : 1,
    'rank',
    'id'
  ]);
}

export { sortedTodosFor }
