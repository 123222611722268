import { React, Component, _, View, TouchableOpacity, Image, Text, Tooltip, styleSpread, Button, resourceActions} from '../index.js';
import { connect, setAppData, setActiveOrg } from '@symbolic/redux';
import { Link, Label, Popup, TrialStatusButton, NetworkIndicator, PickerInput, OrgIcon } from '@symbolic/rn-lib';
import { withRouter } from 'react-router-native';
import { setActiveView } from '~/redux/active-view/active-view';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { Keyboard, Platform, Modal } from 'react-native';

import K from '~/k';
// import HelpPopup from '../popups/help/help-popup';
// import UrlPattern from 'url-pattern';
// import homeImage from '../../assets/home-icon-white.png';
// import shareImage from '../../assets/share.png';
// import backIcon from '~/assets/left-arrow-white.png';
import styles from './header-content.styles.js';
import trashIcon from '~/assets/trash-icon-white.png';
import hideKeyboardIcon from '~/assets/hide-keyboard-icon-white.png';

var s = styleSpread(styles);

class HeaderContent extends Component {
  state = {keyboardIsShowing: false};

  componentDidMount() {
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  componentWillUnmount(...args) {
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  handleKeyboardShow = () => this.setState({keyboardIsShowing: true});

  handleKeyboardHide = () => this.setState({keyboardIsShowing: false});

  // openCollaboratorsMenu = () => {
  //   this.props.setActiveView({data: {sharePopupIsVisible: true, isViewingCollaborators: true}});
  // }

  // finishTutorial = () => {
  //   this.props.setActiveView({data: {showingTutorial: false, tutorialStep: undefined, tutorialComplete: false}, spreadData: false});

  //   this.props.setAppData({key: 'hasSeenTutorial', value: true, appKey: 'todot'});
  // }

  render() {
    return (
      <View style={{justifyContent: 'center', flexDirection: 'row'}}>
        <View style={{flexDirection: 'row'}}>
          <View style={{position: 'relative'}}>
            <OrgIcon
              org={this.props.session.activeOrg}
              onPress={() => this.setState({isPickingActiveOrg: !this.state.isPickingActiveOrg})}
            />
            {this.state.isPickingActiveOrg && (
              <Modal style={{width: '100%', height: '100%', position: 'fixed', backgroundColor: 'red'}}>
                <View style={{borderBottomRightRadius: K.borderRadius, position: 'absolute', top: 0, marginTop: K.spacing * 2 + K.button.height, left: 0, padding: K.spacing, backgroundColor: 'black', zIndex: 1}}>
                  {_.map(this.props.session.orgs, org => (
                    <OrgIcon
                      key={org.id}
                      org={org}
                      onPress={() => {
                        this.props.setActiveOrg({activeOrgId: org.id});
                        this.setState({isPickingActiveOrg: !this.state.isPickingActiveOrg});
                      }}
                      style={{marginBottom: K.margin - 1, borderWidth: 1, padding: 2, borderColor: `rgba(255, 255, 255, ${org.id === this.props.session.activeOrg.id ? 1 : 0.3})`, borderRadius: K.calc(25)}}
                    />
                  ))}
                </View>
              </Modal>
            )}
          </View>
          <Button text={'Zen'} mode='dark' onPress={() => this.props.updateViewMode('zen')}/>
          {this.state.keyboardIsShowing && (
            <Button
              icon={hideKeyboardIcon}
              mode='dark'
              onPress={() => Keyboard.dismiss()}
            />
          )}
          {!K.isWeb && this.props.activeTodo && (
            <Button
              mode='dark'
              style={{marginLeft: K.margin}}
              icon={trashIcon}
              onPress={async () => {
                this.props.destroyTodo({id: this.props.activeTodo.id});
              }}
            />
          )}
        </View>
        <View style={{flex: 1}}/>
        {/*<View style={{flex: 1, alignItems: 'center'}}>
          <Text style={{fontSize: K.calcFont(15), backgroundColor: K.colors.gray, borderRadius: K.borderRadius, padding: K.margin}}>ToDoT</Text>
        </View>*/}
      </View>
    );
  }
  // render() {
  //   var {activeView, setActiveView, activeProcessInstance, activeProcessInstanceType} = this.props;
  //   var isHome = _.includes(['', '/'], this.props.location.pathname);

  //   var {showingTutorial, tutorialStep, tutorialComplete} = activeView.data;
  //   var showShareLink = !K.isWeb && _.some(['categories', 'projects'], keyword => _.includes(this.props.location.pathname, keyword));

  //   return (
  //     <View style={{...styles.header}}>
  //       {showingTutorial ? (
  //         <View {...s.tutorialInfo}>
  //           <Button label='Exit' style={{...styles.button, width: 'auto'}} textStyle={{color: 'white'}} onPress={this.finishTutorial}/>
  //           <Label {...s.tutorialText}>{`${tutorialStep}/4: ${['Press + to start', 'Enter a title', 'Name your steps', 'More info'][tutorialStep - 1] || 'Tutorial'} `}</Label>
  //         </View>
  //       ) : (<>
  //       {K.isWeb && (
  //         <View {...s.centeredContent}>
  //           <View {...s.titleContainer}>
  //             <Text {...s.protagonistTitle}>protagonist</Text>
  //           </View>
  //         </View>
  //       )}
  //       <View {...s.leftContent}>
  //         {!isHome && (
  //           <Tooltip left text='Home'>
  //             <Link to='/' style={{...styles.button, marginRight: K.margin}}>
  //               <Image {...s.backImage} source={homeImage}/>
  //             </Link>
  //           </Tooltip>
  //         )}
  //         {activeProcessInstance && !this.state.cameFromHome && (
  //           <Tooltip left text={categoryTitleFor({processType: activeProcessInstanceType, isSingleUse: _.get(activeProcessInstanceType, 'isSingleUse', 1), orgs: this.props.session.orgs, org: _.find(this.props.session.orgs, {id: activeProcessInstance.orgId})})}>
  //             <Link to={this.props.getBackLink({ignoreCameFromHome: true})} style={{...styles.button, marginRight: K.margin}}>
  //               <Image {...s.backImage} source={backIcon}/>
  //             </Link>
  //           </Tooltip>
  //         )}
  //         <TrialStatusButton/>
  //       </View>
  //       <View style={{...styles.rightContent, flexGrow: 1, width: 'auto'}}>
  //         <View {...s.rightButtonsContainer}>
  //           <View style={{marginRight: K.spacing + K.margin}}>
  //             <NetworkIndicator />
  //           </View>
  //           <Tooltip text='Help Menu'>
  //             <TouchableOpacity
  //               style={{...styles.button, backgroundColor: 'white', width: 'auto', paddingHorizontal: K.spacing}}
  //               onPress={() => setActiveView({data: {helpPopupIsVisible: true}})}
  //             >
  //               <Label style={{color: 'black', opacity: 1}}>Help</Label>
  //             </TouchableOpacity>
  //           </Tooltip>
  //           {(this.props.isProcessTypeShowView || showShareLink) && (
  //             <Tooltip text='Share Menu'>
  //               <TouchableOpacity
  //                 style={{...styles.button, marginLeft: K.margin}}
  //                 onPress={() => setActiveView({data: {sharePopupIsVisible: true}})}
  //               >
  //                 <Image {...s.shareImage} source={shareImage}/>
  //               </TouchableOpacity>
  //             </Tooltip>
  //           )}
  //         </View>
  //       </View>
  //       </>)}
  //       {(showingTutorial && tutorialComplete) && (
  //         <Popup closeDisabled>
  //           <Text style={{marginTop: K.spacing}}>{`Well done! Those are the basics.\n\nYour team may already be using Todot for various projects.\n\nDon't be afraid to try using it for new projects too!`}</Text>
  //           <Button style={{marginTop: K.spacing * 3}} onPress={this.finishTutorial} text='Finish'/>
  //         </Popup>
  //       )}
  //       {this.props.activeView.data.helpPopupIsVisible && (
  //         <HelpPopup startCopilot={this.props.startCopilot} {...{activeProcessInstance}}/>
  //       )}
  //     </View>
  //   );
  // }
}

export default withSafeAreaInsets(withRouter(connect({
  mapState: (state, ownProps) => {
    return {
      users: state.resources.users.byId,
      activeView: state.activeView,
      session: state.session,
      activeTodo: _.get(state.resources, `todos.byId.${_.get(state.activeView, 'data.activeTodoId')}`)
    };
  },
  mapDispatch: {
    setActiveView, setAppData, setActiveOrg,
    ..._.pick(resourceActions.todos, ['destroyTodo'])
  }
})(HeaderContent)));
