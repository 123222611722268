import {
  React, Component, View, ScrollView, styleSpread, Button, logAnalyticsEvent, resourceActions,
  Text, TouchableOpacity, Image, Loading, TextInput
} from '~/components'; //eslint-disable-line
import { Animated } from 'react-native';

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect, setAppData } from '@symbolic/redux';
import { Popup, Label, confirm, withKeyEvents, AttentionIndicator } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';

import lib, { api, colors } from '@symbolic/lib';

import _ from '@symbolic/lodash';
import K from '~/k';
import styles from './todo.styles';

import Todo from '~/components/todo/todo';

var s = styleSpread(styles);

class TodoForm extends Component {
  state = {
    todo: {
     body: '',
     status: 'incomplete'
    }
  };

  getInputRef = (ref) => {
    this.inputRef = ref;
  }

  onSubmit = () => {
    var {todo} = this.state;
    var {color, session} = this.props;

    if (todo.body) {
      this.props.createTodo({props: {body: todo.body, status: todo.status, color: '#cccccc', userId: session.user.id, orgId: session.activeOrg.id, rank: 0}});

      var updates = _.map(this.props.todos, todo => ({props: {rank: todo.rank + 1}, where: {id: todo.id}}));

      this.props.updateTodos({updates});

      this.setState({todo: {body: '', status: 'incomplete'}}); //TODO reset input height

      setTimeout(() => {
        if (this.inputRef) this.inputRef.focus()
      });
    }
  }

  handleTodoChange = ({value}) => {
    var {todo} = this.state;

    this.setState({todo: {...todo, body: value}});
  }

  handleDotPress = () => {
    var {todo} = this.state;

    this.setState({todo: {...todo, status: todo.status === 'complete' ? 'incomplete' : 'complete'}});
  }

  render() {
    var {color, viewMode} = this.props;
    var {todo} = this.state;

    var diameter = K.step.height;

    var dotStyle = {
      width: diameter,
      height: diameter,
      borderRadius: diameter / 2,
      borderWidth: 1
    }

    return (
      <View style={{marginBottom: K.margin, flexDirection: 'row', ...(todo.status === 'complete' ? {opacity: 0.5} : {})}}>
        <TouchableOpacity
          onPress={this.handleDotPress}
          style={{marginRight: K.margin, justifyContent: 'center', alignItems: 'center', justifyContent: 'center', alignItems: 'center', backgroundColor: '#cccccc33', borderColor: '#cccccc', ...dotStyle}}
        />
        <TextInput
          placeholder='TODO TITLE...'
          multiline
          getInputRef={this.getInputRef}
          inputStyle={{...(viewMode === 'zen' ? {backgroundColor: 'white'} : {})}}
          style={{backgroundColor: 'transparent', minHeight: K.step.height, paddingVertical: 7, flex: 1, borderRadius: K.step.height / 2, ...(todo.status === 'complete' ? {textDecorationLine: 'line-through', textDecorationStyle: 'solid'} : {})}}
          value={todo.body}
          onInput={this.handleTodoChange}
          blurOnSubmit
          onSubmitEditing={this.onSubmit}/>
      </View>
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: (state, ownProps) => (
    {session: state.session, todos: state.resources.todos.byId, ..._.pick(state.activeView.data, ['tutorialStep', 'showingTutorial'])
  }),
  mapDispatch: {
    ..._.pick(resourceActions.todos, ['trackTodos', 'updateTodo', 'updateTodos', 'destroyTodo', 'createTodo']),
    ..._.pick(resourceActions.notepads, ['trackNotepads', 'updateNotepad', 'destroyNotepad']),
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers, setAppData,
  }
})(TodoForm));
