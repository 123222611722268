var slides = [
  {
    title: 'Jot down notes',
    body: 'Turn them into tasks',
    image: null,
    mediaResizeMode: 'contain'
  }
];

export default slides;
